import React from "react";
import ReactLoading from "react-loading";

import "./loader-styles.scss";

const Loader = (props) => {
  const { isLoading } = props;

  if (isLoading) {
    return (
      <div className="loader-container">
        <ReactLoading
          className="loader-bubbles"
          type="bubbles"
          color={"#009688"}
          height={200}
          width={200}
        />
      </div>
    );
  }
};

export default Loader;
