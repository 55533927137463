import axios from "axios";
import baseURL from "../utils/baseURL";

const syncUser = (token, uid) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios.put(`${baseURL()}user/${uid}`, null, config);
};

export default syncUser;
